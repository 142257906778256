import React from 'react'

const WelcomeBanner = () => {
  return (
    <div>
        <h3 className='mt-3'>Welcome to <span style={{color:'#E4A231'}}>Luxlife Dashboard!</span></h3>
        <p>Manage, view and edit through alot of features on our dashboard crafted custom for Luxlife!</p>
    </div>
  )
}

export default WelcomeBanner
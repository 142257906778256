import MainCard from 'components/MainCard';
import { useState } from 'react';
import { Grid, InputLabel, Button } from '@mui/material';
import { Spin, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
const CryptoJS = require("crypto-js");

const UploadExcel = () => {

  const [image, setImage] = useState([])

  let getNewTokenn = localStorage.getItem("token");
  let NewToekn = "";
  if (getNewTokenn) {
    NewToekn = CryptoJS.AES.decrypt(
      getNewTokenn,
      '2d84n0OiymE6eQtvCsdfdstett5zQmm2OsP'
    ).toString(CryptoJS.enc.Utf8);
  }


  const handleSubmit = async () => {
    const headers = {
      Authorization: `Bearer ${NewToekn}`,
    };
  
    // Prepare form data for the request body (assuming "image" is a file)
    const formData = new FormData();
    formData.append('file', image && image.length > 0 && image[0]);
  
    try {
      const response = await fetch('https://www.luxlifemiamirealestate.com/api/api/domainparmeter/import-buildings', {
        method: 'POST',
        body: formData,
        headers,
      });
  
      if (response.ok) {
        const responseData = await response.json();
        toast.success(responseData?.message)
        console.log('responseData:', responseData);
      } else {
        throw new Error('Upload failed with status: ' + response.status);
      }
    } catch (error) {
      // Show a toast notification for errors
      toast.error(`Excel upload failed: ${error.message || 'Unknown error'}`);
    }
  };
  

  const handleImageUpload = ({ file, onSuccess, onError }) => {
    try {
      // Add file to state
      setImage([file]);

      // Indicate successful upload (saving to state)
      onSuccess();
    } catch (error) {
      // Handle any errors
      onError(error);
    }
  };

  const handleImageRemove = (file) => {
    setImage((prev) => prev.filter((item) => item.uid !== file.uid));
  };



  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          height: "75px",
          borderRadius: "12px",
          marginBottom: "20px",
          padding: "0 16px",
          backgroundColor: "#fff",
          border: '1px solid',
          borderColor: 'rgba(228,162,49,0.8)',
        }}
      >
        <Grid container alignItems="center" justifyContent="flex-start">
          <Grid item>
            <span
              style={{
                fontSize: "22px",
                fontWeight: "600",
              }}
            >
              Upload Footer Directory
            </span>
          </Grid>
        </Grid>
      </div>
      <MainCard>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className='row'>
               
                {/* Photos */}
                <div className='col-md-2 my-2'>
                  <InputLabel htmlFor="image-option">Excel*</InputLabel>

                  <Upload
  customRequest={handleImageUpload}
  fileList={image}
  onRemove={handleImageRemove}
  listType="text" 
  showUploadList={true}
  accept=".xlsx, .xls" 
  multiple={false}
>
                    <Button fullWidth
                      className="flex items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none my-1"
                    >
                      <UploadOutlined style={{ marginRight: 10 }} /> Upload Excel
                    </Button>
                  </Upload>
                </div>

                <div className='my-3' style={{ textAlign: "end" }}>
                  <Button
                    onClick={handleSubmit}
                    disableElevation
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                  >
                    Upload Excel
                  </Button>

                </div>
              </div>
            </Grid>
          </Grid>
      </MainCard>
    </>
  );
};

export default UploadExcel;

// material-ui
import { useTheme } from '@mui/material/styles';
import { Grid } from '@mui/material';
// project-imports

import WelcomeBanner from 'sections/dashboard/WelcomeBanner';

// import AssignUsers from 'sections/dashboard/AssignUsers';


// assets


// ==============================|| DASHBOARD - DEFAULT ||============================== //

const Analysis = () => {
  

  let permissionUser = localStorage.getItem('LoginData');
  let permissions = JSON.parse(permissionUser)?.permissions;

  const allowedPermissions = permissions && permissions.length !== 0 ? permissions.map((permission) => permission?.permission) : [];


  const theme = useTheme();
  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      <Grid item xs={12}>
      <div>
        <h3 className='mt-3'>Welcome to <span style={{color:'#E4A231'}}>Luxlife Analytics Dashboard!</span></h3>
        <p><a href="https://analytics.google.com/analytics/web/" target='_blank' style={{color:'#E4A231'}}>Visit google analytics</a> to view analytics for the website</p>
    </div>
      </Grid>
    </Grid>
  );
};

export default Analysis;

